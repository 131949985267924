import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function groupDataReducer(
  state = initialState.groupData,
  action
) {
  switch (action.type) {
    case types.LOADED_GROUP_DATA_SUCCESS:
      return action.groupData;
    default:
      return state;
  }
}
