export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const SUBMITTING_REQUEST_STATUS = "SUBMITTING_REQUEST_STATUS";
export const LOADED_USER_AUTH_SUCCESS = "LOADED_USER_AUTH_SUCCESS";
export const LOADED_USER_PARAM_SUCCESS = "LOADED_USER_PARAM_SUCCESS";
export const LOADED_USER_INFO_SUCCESS = "LOADED_USER_INFO_SUCCESS";
export const LOADED_USER_FORGOT_SUCCESS = "LOADED_USER_FORGOT_SUCCESS";
export const LOADED_SETTING_INFO_SUCCESS = "LOADED_SETTING_INFO_SUCCESS";
export const LOADED_DATA_SUCCESS = "LOADED_DATA_SUCCESS";
export const LOADED_EMAIL_DATA_SUCCESS = "LOADED_EMAIL_DATA_SUCCESS";
export const LOADED_PAGE_DATA_SUCCESS = "LOADED_PAGE_DATA_SUCCESS";
export const LOAD_PAGINATION_DATA_SUCCESS = "LOAD_PAGINATION_DATA_SUCCESS";
export const NETWORK_REQUEST_STATUS = "NETWORK_REQUEST_STATUS";
export const TABLE_HEAD_REQUEST_STATUS = "TABLE_HEAD_REQUEST_STATUS";
export const LOAD_REQUEST_STATUS = "LOAD_REQUEST_STATUS";
export const LOADED_FAQ_DATA_SUCCESS = "LOADED_FAQ_DATA_SUCCESS";
export const LOADED_EMAIL_PARAM_SUCCESS = "LOADED_EMAIL_PARAM_SUCCESS";
export const LOAD_STATUS = "LOAD_STATUS";
export const LOAD_PATH_NAME = "LOAD_PATH_NAME";
export const LOADED_CUSTOMER_DATA_SUCCESS = "LOADED_CUSTOMER_DATA_SUCCESS";
export const LOADED_DASHBOARD_INFO_SUCCESS = "LOADED_DASHBOARD_INFO_SUCCESS";
export const LOADED_USER_NOTIFICATION_DATA_SUCCESS =
  "LOADED_USER_NOTIFICATION_DATA_SUCCESS";
export const LOADED_USER_LIST_DATA_SUCCESS = "LOADED_USER_LIST_DATA_SUCCESS";
export const LOADED_CONTACT_DATA_SUCCESS = "LOADED_CONTACT_DATA_SUCCESS";
export const LOADED_TOASTER_INFO_SUCCESS = "LOADED_TOASTER_INFO_SUCCESS";
export const LOADED_DIALOG_OPEN_SUCCESS = "LOADED_DIALOG_OPEN_SUCCESS";
export const LOADED_NOTIFICATION_COUNT_SUCCESS =
  "LOADED_NOTIFICATION_COUNT_SUCCESS";
export const LOADED_REQUEST_DATA_SUCCESS = "LOADED_REQUEST_DATA_SUCCESS";
export const SUBMITTING_PRE_LOADER = "SUBMITTING_PRE_LOADER";
export const LOADED_SUB_ADMIN_DATA_SUCCESS = "LOADED_SUB_ADMIN_DATA_SUCCESS";
export const LOADED_CATEGORY_DATA_SUCCESS = "LOADED_CATEGORY_DATA_SUCCESS";
export const LOADED_CATEGORY_LIST_SUCCESS = "LOADED_CATEGORY_LIST_SUCCESS";
export const LOADED_VIDEO_DATA_SUCCESS = "LOADED_VIDEO_DATA_SUCCESS";
export const LOADED_DEVICE_DATA_SUCCESS = "LOADED_DEVICE_DATA_SUCCESS";
export const LOADED_NEWS_DATA_SUCCESS = "LOADED_NEWS_DATA_SUCCESS";
export const LOADED_MUSIC_DATA_SUCCESS = "LOADED_MUSIC_DATA_SUCCESS";
export const LOADED_WEB_DATA_SUCCESS = "LOADED_WEB_DATA_SUCCESS";
export const LOADED_ADMIN_DATA_SUCCESS = "LOADED_ADMIN_DATA_SUCCESS";
export const LOADED_USER_SELECT_SUCCESS = "LOADED_USER_SELECT_SUCCESS";
export const LOADED_SUBSCRIPTION_DATA_SUCCESS =
  "LOADED_SUBSCRIPTION_DATA_SUCCESS";
export const LOADED_COURSE_DATA_SUCCESS = "LOADED_COURSE_DATA_SUCCESS";
export const LOADED_QUIZ_DATA_SUCCESS = "LOADED_QUIZ_DATA_SUCCESS";
export const LOADED_QUIZ_TOPIC_DATA_SUCCESS = "LOADED_QUIZ_TOPIC_DATA_SUCCESS";
export const LOADED_TOPIC_LIST_SUCCESS = "LOADED_TOPIC_LIST_SUCCESS";
export const LOADED_BANNER_DATA_SUCCESS = "LOADED_BANNER_DATA_SUCCESS";
export const LOADED_USER_ROLE_DATA_SUCCESS = "LOADED_USER_ROLE_DATA_SUCCESS";
export const LOADED_USER_ROLE_LIST_SUCCESS = "LOADED_USER_ROLE_LIST_SUCCESS";
export const LOADED_POST_DATA_SUCCESS = "LOADED_POST_DATA_SUCCESS";
export const LOADED_GROUP_DATA_SUCCESS = "LOADED_GROUP_DATA_SUCCESS";
export const LOADED_POLL_DATA_SUCCESS = "LOADED_POLL_DATA_SUCCESS";
export const LOADED_JOB_DATA_SUCCESS = "LOADED_JOB_DATA_SUCCESS";
