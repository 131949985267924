export const LOGIN = "auth/login";
export const FORGOT_PASSWORD_EMAIL = "auth/forgot-password";
export const UPDATE_PASSWORD_WITH_OTP = "auth/update-password-with-otp";
export const RESEND_OTP_EMAIL = "auth/resent-otp-email";
export const SETTING = "setting";
export const UPDATE_PROFILE = "users/admin/profile-update";
export const UPDATE_PASSWORD = "users/change-password";
export const LOAD_EMAIL = "email";
export const LOAD_PAGE = "cms";
export const LOAD_FAQ = "faq";
export const LOAD_CUSTOMER = "customer";
export const LOAD_CUSTOMER_PASSWORD = "customer/change-password";
export const DASHBOARD = "users/dashboard";
export const GET_USER_LIST = "users/userList";
export const SEND_NOTIFICATION = "notification/sendNotification";
export const GET_NOTIFICATION = "notification";
export const DELETE_NOTIFICATION = "notification/delete";
export const LOAD_ADMIN_PASSWORD = "admin/change-password";
export const LOAD_BANNER = "banner";
export const LOAD_USER_ROLE = "userRole";
export const LOAD_POST = "post/admin";
export const LOAD_GROUP = "group/admin";
export const LOAD_POLL = "poll/admin";
export const LOAD_JOB = "job/admin";
