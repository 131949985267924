import { combineReducers } from "redux";
import initialState from "./initialState";
import isSuperUserAuth from "./isAuthRequest";
import isSubmitting from "./isSubmittingRequest";
import superUserParams from "./userParamsReducer";
import superUserInfo from "./userInfoReducer";
import collapseMenu from "./collapseMenuRequest";
import setting from "./settingReducer";
import isData from "./isDataRequest";
import emailData from "./emailReducer";
import pageData from "./pageDataReducer";
import pagination from "./paginationReducers";
import networkRequest from "./networkRequest";
import isHead from "./isHeadRequest";
import isLoad from "./isLoadRequest";
import faqData from "./faqDataReducer";
import reducer from "./collapseReducer";
import pathName from "./isPathNameRequest";
import dashboard from "./dashboardReducer";
import notificationList from "./notificationDataReducer";
import requestParams from "./requestParamsReducer";
import toaster from "./toasterReducer";
import dialogOpen from "./dialogOpenReducer";
import notificationCount from "./notificationCountReducer";
import preLoader from "./preLoaderRequest";
import bannerData from "./bannerDataReducer";
import userRoleData from "./userRoleDataReducer";
import userRoleList from "./userRoleListReducer";
import customerData from "./customerDataReducer";
import postData from "./postDataReducer";
import groupData from "./groupDataReducer";
import pollData from "./pollDataReducer";
import jobData from "./jobDataReducer";
import userList from "./userListReducer";

const rootReducer = combineReducers({
  isSuperUserAuth,
  isSubmitting,
  superUserParams,
  superUserInfo,
  collapseMenu,
  setting,
  isData,
  emailData,
  pagination,
  isFetching: networkRequest,
  isHead,
  pageData,
  isLoad,
  faqData,
  reducer,
  pathName,
  dashboard,
  notificationList,
  requestParams,
  toaster,
  dialogOpen,
  notificationCount,
  preLoader,
  bannerData,
  userRoleData,
  userRoleList,
  customerData,
  postData,
  groupData,
  pollData,
  jobData,
  userList,
});

export default function combinedRootReducer(state, action) {
  return action.type === "LOG_OUT"
    ? rootReducer(initialState, action)
    : rootReducer(state, action);
}
