import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function jobDataReducer(state = initialState.jobData, action) {
  switch (action.type) {
    case types.LOADED_JOB_DATA_SUCCESS:
      return action.jobData;
    default:
      return state;
  }
}
