import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Card } from "react-bootstrap";
import Button from "../../../Component/Button";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { userLoginData } from "../../../actions/userActions";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import logo from "../../../assets/images/logo.png";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { formLabelsTheme } from "../../../utils/helpers";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "../../../Component/Alert";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#212529",
    },
    "& .Mui-focused": {
      color: "#212529",
    },
  },
});

const Index = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { title } = props;
  const { superUserParams } = useSelector((state) => ({
    superUserParams: state.superUserParams,
  }));
  const { register, errors, handleSubmit } = useForm({
    defaultValues: superUserParams,
  });
  const onSubmit = (data) => {
    let params = data;
    params.role_id = 1;
    dispatch(userLoginData(params));
  };
  const [values, setValues] = useState({
    password: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, password: !values.password });
  };
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <Fragment>
      <Alert />
      <div className="auth-wrapper aut-bg-img">
        <div className="auth-content">
          <Card className="borderless text-center">
            <Card.Body>
              <div className="mb-5">
                <img
                  src={logo}
                  alt="logo"
                  className="auth_logo"
                  style={{
                    height: "100px",
                  }}
                />
              </div>
              <MuiThemeProvider theme={formLabelsTheme}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  autoComplete="off"
                >
                  <div className="input-group mb-3 w-100">
                    <TextField
                      required
                      id="outlined-email"
                      label="Email Address"
                      variant="outlined"
                      autoFocus={true}
                      fullWidth
                      className={!errors.email ? classes.root : "w-100"}
                      error={errors.email ? true : false}
                      name="email"
                      inputRef={register({
                        required: "Please enter email address.",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address.",
                        },
                        maxLength: {
                          value: 50,
                          message:
                            "Email address should not exceed 15 characters.",
                        },
                      })}
                      helperText={errors.email && errors.email.message}
                    />
                  </div>
                  <div className="input-group mb-4">
                    <TextField
                      id="outlined-password"
                      required
                      label="Password"
                      type={values.password ? "text" : "password"}
                      variant="outlined"
                      fullWidth
                      className={!errors.password ? classes.root : "w-100"}
                      error={errors.password ? true : false}
                      name="password"
                      inputRef={register({
                        required: "Please enter password.",
                      })}
                      helperText={errors.password && errors.password.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}>
                              {values.password ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="form-group text-left">
                    <div className="checkbox checkbox-fill d-inline">
                      <input
                        type="checkbox"
                        label="Remember me"
                        name="remember_me"
                        ref={register}
                        id="checkbox-fill-a1"
                      />
                      <label htmlFor="checkbox-fill-a1" className="cr">
                        Remember Me
                      </label>
                    </div>
                  </div>
                  <div className="w-100">
                    <Button title={"Submit"} />
                  </div>
                  <p className="mb-2 text-muted pt-3">
                    Forgot password?&nbsp;
                    <NavLink to="/forgot-password">Reset</NavLink>
                  </p>
                </form>
              </MuiThemeProvider>
            </Card.Body>
          </Card>
          <p className="pt-5 footer_title">2023 © Sportive365 Admin</p>
        </div>
      </div>
    </Fragment>
  );
};

export default Index;
