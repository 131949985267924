import { createTheme } from "@material-ui/core/styles";
import Moment from "moment";
var ucfirst = require("ucfirst");
export const formLabelsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#f00",
        "&$error": {
          color: "#f00",
        },
      },
    },
  },
});

export const renderTitle = (title) => {
  if (!title) {
    return "";
  } else {
    var str = title;
    var n = str.length;
    if (n === 0) {
      return "";
    } else {
      var res = str.slice(0, 30);
      var dot = "";
      if (n > 30) {
        dot = "...";
      }
      return res + dot;
    }
  }
};

export const catchError = (response) => {
  if (response instanceof Error) {
    throw new Error(response.response.data.message);
  }
};

export const toUcFirst = function (str) {
  return str ?? ucfirst(str) ?? "N/A";
};

export const textFold = function (input, lineSize) {
  const output = [];
  let outputCharCount = 0;
  let outputCharsInCurrentLine = 0;
  for (var i = 0; i < input.length; i++) {
    const inputChar = input[i];
    output[outputCharCount++] = inputChar;
    if (inputChar === "\n") {
      outputCharsInCurrentLine = 0;
    } else if (outputCharsInCurrentLine > lineSize - 2) {
      output[outputCharCount++] = "\n";
      outputCharsInCurrentLine = 0;
    } else {
      outputCharsInCurrentLine++;
    }
  }
  return output.join("");
};

export const checkSpace = function (aa) {
  if (aa.startsWith(" ") || aa.endsWith(" ")) {
    return false;
  } else {
    return true;
  }
};

export const date = function (date) {
  return Moment(date).format("DD-MMM-YYYY");
};

export const dateTime = function (date) {
  return Moment(date).format("DD-MM-YYYY HH:mm:ss");
};

export const removeSpaceFromStart = (e) => {
  const value = e.target.value;
  // only for react useState value variable will be the initail value and after change value
  if (e.key === "Enter" || (value.length === 0 && e.key === " ")) {
    e.preventDefault();
  } else {
    if (value.endsWith(" ")) {
      e.preventDefault();
      return true;
    }
  }
};

export const checkNumber = (event) => {
  var k = event.keyCode;
  if (k === 9) {
    return true;
  }
  if (k !== 8) {
    if ((k < 48 || k > 57) && (k < 96 || k > 105)) {
      event.preventDefault();
      return false;
    }
  }
};

export const checkMobileNumber = (event) => {
  var k = event.keyCode;
  if (k === 9) {
    return true;
  }
  if (k !== 8) {
    if ((k < 48 || k > 57) && (k < 96 || k > 105)) {
      event.preventDefault();
      return false;
    }
  }
};

export const checkCharges = (event) => {
  var value = event.target.value;
  var k = event.keyCode;
  if (k === 9) {
    return true;
  }
  if (parseInt(event.key) === 0 && value.length === 0) {
    event.preventDefault();
    return false;
  } else if (k !== 8) {
    if (k === 110 || k === 190) {
      return true;
    }
    if ((k < 48 || k > 57) && (k < 96 || k > 105)) {
      event.preventDefault();
      return false;
    }
  }
};

export const ValidateAlpha = (event) => {
  var k = event.keyCode;
  if (k === 9) {
    return true;
  }
  if (k !== 8) {
    if (k > 31 && (k < 65 || k > 90) && (k > 97 || k < 122)) {
      event.preventDefault();
      return false;
    }
  }
};

export const dateFromNow = function (date) {
  return Moment(date).fromNow();
};

export const checkPermission = (userInfo, id) => {
  if (userInfo.role_id === 3) {
    if (id === 0) {
      return true;
    }
    let obj = userInfo.permission.find((element) => element.id === id);
    if (obj && obj.id) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
